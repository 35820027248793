import axios from '@axios'

export default function chatsServices() {
  const getChatMessages = async (queryParams) => {
    const api = await axios.get('/posts/id', {
      params: queryParams,
    })
    return api
  }

  const sendMessages = async (queryParams) => {
    const api = await axios.post('/posts', queryParams)
    return api
  }

  return {
    getChatMessages,
    sendMessages,
  }
}
