<template>
  <div>
    <b-card class="floating-chat">
      <b-col
        class="chat"
        cols="12"
        md="12"
        sm="12"
      >
        <div class="header">
          <span class="title">
            <feather-icon
              icon="MessageSquareIcon"
              size="35"
            /> Chat da live
          </span>
        </div> 
        <ul class="messages">
          <li
            v-for="message in liveMessages"
            :key="message.id"
            :style="message.user.photo == null ? `--bg-image: url('${apiBase}/images/cropped-blank-profile-picture-973460_1280.png')` : `--bg-image: url('${apiBase}/storage/image/${message.user.photo}`"
            :class="sessionInfo.user.id == message.user_id ? 'other' : 'self'"
          >
            <div class="message-user-name">
              {{ message.user.name }}
            </div>
            {{ message.text }}
          </li>
        </ul>
        <div class="row">
          <div class="textarea-wrapper col-md-12">
            <b-textarea
              v-model="text"
              rows="1"
              class="form-control text-box mentionable"
              placeholder="Digite uma mensagem..."
            />
            <b-button
              variant="btn-primary"
              class="button-chat"
              :disabled="!text"
              @click="sendMessage()"
            >
              <feather-icon icon="SendIcon" size="22" class="rotate-right" />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-card>
  </div>
</template>

<script>

import $ from 'jquery'
import Tribute from 'tributejs'
import ChatsServices from './chatsServices'

export default {
  components: {},
  props: {
    liveId: Number,
    userId: Number,
  },
  data() {
    return {
      element: null,
      close: false,
      text: null,
      timer: 0,
      mentions: {},
      users: [],
      liveMessages: [],
      apiBase: process.env.VUE_APP_API_BASE
    }
  },
  computed: {
    sessionInfo() {
      return this.$store.state.auth.sessionInfo
    },
  },
  watch: {
    async liveId() {
      ChatsServices().getChatMessages({ live_id: this.liveId }).then(res => {
        this.liveMessages = res.data.response.messages
      })
    },
    liveMessages() {
      this.liveMessages.forEach(element => {
        if (this.users.filter(e => e.id === element.user.id).length === 0) {
          this.users.push({
            id: element.user.id,
            key: element.user.name,
            value: element.user.name
          })
        }
      })
    },
    users() {
      this.mentions = new Tribute({
        collection: [
          {
            // symbol or string that starts the lookup
            trigger: '@',

            // element to target for @mentions
            iframe: null,

            // class added in the flyout menu for active item
            selectClass: 'highlight',

            // class added to the menu container
            containerClass: 'tribute-container',

            // class added to each list item
            itemClass: '',

            // function called on select that returns the content to insert
            selectTemplate(item) {
              return `@${item.original.value}`
            },

            // template for displaying item in menu
            menuItemTemplate(item) {
              return item.string
            },

            // template for when no match is found (optional),
            // If no template is provided, menu is hidden.
            noMatchTemplate: null,

            // specify an alternative parent container for the menu
            // container must be a positioned element for the menu to appear correctly ie. `position: relative;`
            // default container is the body
            menuContainer: document.body,

            // column to search against in the object (accepts function or string)
            lookup: 'key',

            // column that contains the content to insert by default
            fillAttr: 'value',

            // REQUIRED: array of objects to match or a function that returns data (see 'Loading remote data' for an example)
            values: this.users,

            // When your values function is async, an optional loading template to show
            loadingItemTemplate: null,

            // specify whether a space is required before the trigger string
            requireLeadingSpace: true,

            // specify whether a space is allowed in the middle of mentions
            allowSpaces: false,

            // optionally specify a custom suffix for the replace text
            // (defaults to empty space if undefined)
            replaceTextSuffix: '\n',

            // specify whether the menu should be positioned.  Set to false and use in conjuction with menuContainer to create an inline menu
            // (defaults to true)
            positionMenu: true,

            // when the spacebar is hit, select the current match
            spaceSelectsMatch: false,

            // turn tribute into an autocomplete
            autocompleteMode: false,

            // Customize the elements used to wrap matched strings within the results list
            // defaults to <span></span> if undefined
            searchOpts: {
              pre: '<span>',
              post: '</span>',
              skip: false // true will skip local search, useful if doing server-side search
            },

            // Limits the number of items in the menu
            menuItemLimit: 3,

            // specify the minimum number of characters that must be typed before menu appears
            menuShowMinLength: 0
          }
        ]
      })
      // also works with NodeList
      this.mentions.attach(document.querySelectorAll('.mentionable'))
    }
  },
  mounted() {
    this.element = $('.floating-chat')

    setTimeout(() => {
      this.element.addClass('enter')
      this.element.addClass('expandirChat')
      this.element.find('.chat').addClass('enter').show()
    }, 1000)

    this.getMessages()
    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      this.timer++
      if (this.timer === 5) {
        this.getMessages()
        this.timer = 0
      }
    }, 1000)
  },
  methods: {
    sendMessage() {
      const data = {
        user_id: this.userId,
        live_id: this.liveId,
        text: this.text
      }
      if (this.text !== null && this.text !== '') {
        ChatsServices().sendMessages(data)
      }

      this.text = null
    },
    async getMessages() {
      ChatsServices().getChatMessages({ live_id: this.liveId }).then(res => {
        this.liveMessages = res.data.response.messages
      })
    },
    scrollBottom() { window.scrollTo(0, 99999) }
  }

}
</script>

<style lang="scss" scoped>
// Imports
// --------------------------------------
@import 'https://fonts.googleapis.com/css?family=Noto+Sans';
// Variables
// --------------------------------------
$scrollbar-width: 5px;
$chat-thread-bgd-color: rgba(25, 147, 147, 0.2);
$chat-thread-msg-arrow-size: 10px;
$chat-thread-avatar-size: 25px;
$chat-thread-offset: #{$chat-thread-avatar-size + 20px};
$default-shadow-color: rgb(0, 0, 0);
// Mixins
// --------------------------------------
@mixin fancy-background() {

}

@mixin fancy-scrollbar() {
    &::-webkit-scrollbar {
        width: $scrollbar-width;
    }
    &::-webkit-scrollbar-track {
        border-radius: $scrollbar-width;
        background-color: rgba(25, 147, 147, 0.1);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: $scrollbar-width;
        background-color: $chat-thread-bgd-color;
    }
}

@mixin scrolling-shadows($background-color: transparent, $shadow-intensity: 0.5, $shadow-color: $default-shadow-color, $cover-size: 40px, $shadow-size: 14px) {
    // Shadow covers
    background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)), radial-gradient(50% 100%, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)) 0 100%;
    background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0));
    // also add button shadow:
    //radial-gradient(farthest-side at 50% 100%, rgba($shadow-color,$shadow-intensity), rgba($shadow-color,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: $background-color;
    background-size: 100% $cover-size, 100% $cover-size, 100% $shadow-size, 100% $shadow-size;
    // Opera doesn't support this in the shorthand
    background-attachment: local, local, scroll, scroll;
}

* {
    box-sizing: border-box;
}

body {
    font: 12px/16px 'Noto Sans', sans-serif;
}
.message-user-name {
  font-size: 15px;
  //text-decoration: underline;
  font-weight: bolder;
  margin-bottom: 5px;
}
.textarea-wrapper {
  display: flex;
  justify-content: space-between; /* Create space between textarea and button */
}
.floating-chat {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    right: 0px;
    width: 80px;
    height: 80px;
    transform: translateY(70px);
    transition: all 250ms ease-out;
    border-radius: 50%;
    opacity: 0;
    @include fancy-background;
    &.enter:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        opacity: 1;
    }
    &.enter {
        transform: translateY(0);
        opacity: 0.6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
    }
    &.expandirChat {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        cursor: auto;
        opacity: 1;
    }
    :focus {
        outline: 0;
    }

    .button-chat {
      border-radius: 120px; 
      padding: 0; 
    }
    .chat {
        display: flex;
        flex-direction: column;
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        border-radius: 50%;
        transition: all 250ms ease-out;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &.enter {
            opacity: 1;
            border-radius: 0;
            margin: 10px;
            width: auto;
            height: auto;
        }
        .header {
            flex-shrink: 0;
            padding-bottom: 10px;
            display: flex;
            .title {
                flex-grow: 1;
                flex-shrink: 1;
                padding: 0 5px;
            }
            button {
                flex-shrink: 0;
            }
        }
        .messages {
            padding: 10px;
            margin: 0;
            list-style: none;
            overflow: auto;
            flex-grow: 1;
            border-radius: 4px;
            background: transparent;
            @include fancy-scrollbar;
            li {
                position: relative;
                clear: both;
                display: inline-block;
                padding: 14px;
                margin: 0 0 20px 0;
                font: 16px 'Noto Sans', sans-serif;
                border-radius: 10px;
                background-color: $chat-thread-bgd-color;
                word-wrap: break-word;
                max-width: 81%;
                &:before {
                    position: absolute;
                    top: 0;
                    width: $chat-thread-avatar-size;
                    height: $chat-thread-avatar-size;
                    border-radius: $chat-thread-avatar-size;
                    content: '';
                    background-size: cover;
                }
                &:after {
                    position: absolute;
                    top: $chat-thread-msg-arrow-size;
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: $chat-thread-msg-arrow-size solid $chat-thread-bgd-color;
                }
            }
            li.other {
                animation: show-chat-odd 0.15s 1 ease-in;
                -moz-animation: show-chat-odd 0.15s 1 ease-in;
                -webkit-animation: show-chat-odd 0.15s 1 ease-in;
                float: right;
                margin-right: $chat-thread-offset;
            }
            li.other:before {
                right: -$chat-thread-offset;
                // Placeholder avatar 1
                background-image: var(--bg-image);
            }
            li.other:after {
                border-right: $chat-thread-msg-arrow-size solid transparent;
                right: -$chat-thread-msg-arrow-size;
            }
            li.self {
                animation: show-chat-even 0.15s 1 ease-in;
                -moz-animation: show-chat-even 0.15s 1 ease-in;
                -webkit-animation: show-chat-even 0.15s 1 ease-in;
                float: left;
                margin-left: $chat-thread-offset;
            }
            li.self:before {
                left: -$chat-thread-offset;
                // Placeholder avatar 2
                background-image:  var(--bg-image);
            }
            li.self:after {
                border-left: $chat-thread-msg-arrow-size solid transparent;
                left: -$chat-thread-msg-arrow-size;
            }
        }

        .text-box {
            flex-grow: 1; /* Allow the textarea to take available space */
            margin-right: 5px;
            border-radius: 3px;
            width: 100%;
            overflow-y: auto;
            padding-left: 2px;
            padding-right: 2px;
            @include fancy-scrollbar;
        }

        .rotate-right {
          transform: rotate(45deg);
          color: #FFF;
        }

    }
}

// Animation
// --------------------------------------
@keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }
    100% {
        margin-left: 0;
    }
}

@-moz-keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }
    100% {
        margin-left: 0;
    }
}

@-webkit-keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }
    100% {
        margin-left: 0;
    }
}

@keyframes show-chat-odd {
    0% {
        margin-right: -480px;
    }
    100% {
        margin-right: 0;
    }
}

@-moz-keyframes show-chat-odd {
    0% {
        margin-right: -480px;
    }
    100% {
        margin-right: 0;
    }
}

@-webkit-keyframes show-chat-odd {
    0% {
        margin-right: -480px;
    }
    100% {
        margin-right: 0;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
